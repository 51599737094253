import { useEffect, useState } from 'react'
import { useSplitClient, useTrack } from '@splitsoftware/splitio-react'
import { isBrowser } from 'lib/browser/helpers'

export type Params = {
  eventName: string
  value?: number
  properties?: SplitIO.Properties
}

export const useRecordSplitPageview = (
  eventName: Params['eventName'],
  value?: Params['value'],
  properties?: Params['properties']
): void => {
  if (!isBrowser()) {
    return
  }

  const { isReady } = useSplitClient()

  const [recorded, setRecorded] = useState(false)
  const track = useTrack()

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (!recorded) {
      track('anonymous', eventName, value, properties)
      setRecorded(true)
    }
  }, [isReady])
}
